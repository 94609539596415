import BlockAdvancedFilters from "@/components/blocks/block-advanced-filters";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { BoxIcon } from "lucide-react";
import TableBlocks from "@/components/shared/table-blocks";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Block,
  BlockDocument,
  BlockFilter,
  BlocksOrderBy,
  DashboardStatsDocument,
  TransactionsDocument,
  TransactionsOrderBy,
} from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import TransactionMetrics from "@/components/shared/transaction-metrics";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import { useTitle } from "../hooks/use-title";
import { LOCAL_STORAGE_KEYS, MEMPOOL_LABEL } from "../utils/constants";
import { BlockFilters } from "../types";
import Spinner from "@/components/shared/spinner";
import { formatDateFiltersPayload } from "../utils/helpers";

const ITEMS_PER_PAGE = 10;
const TRANSACTIONS_IN_BLOCK = 3 + 1; // we want to show 3 last transactions, but hide the "Received block reward" one that is usually the last one

const PER_PAGE_OPTIONS = [ITEMS_PER_PAGE, 20, 50, 100];

const DEFAULT_FILTERS: BlockFilters = {
  date: {
    from: undefined,
    to: undefined,
  },
  blockHeight: "",
  blockHash: "",
};

export const Blocks = () => {
  useTitle("Blocks");

  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const itemsPerPageStored = localStorage.getItem(
    LOCAL_STORAGE_KEYS.blocksPerPage,
  );
  const initItemsPerPage = itemsPerPageStored
    ? Number.parseInt(itemsPerPageStored)
    : ITEMS_PER_PAGE;

  const [filters, setFilters] = useState<BlockFilters>({
    blockHeight: searchParams.get("height") || DEFAULT_FILTERS.blockHeight,
    blockHash: searchParams.get("hash") || DEFAULT_FILTERS.blockHash,
    date: {
      from:
        searchParams.get("from") && dayjs(searchParams.get("from")).isValid()
          ? new Date(searchParams.get("from") as string)
          : DEFAULT_FILTERS.date.from,
      to:
        searchParams.get("to") && dayjs(searchParams.get("to")).isValid()
          ? new Date(searchParams.get("to") as string)
          : DEFAULT_FILTERS.date.from,
    },
  });
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [blocks, setBlocks] = useState<Array<Block>>([]);
  const [blocksTotal, setBlocksTotal] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(initItemsPerPage);
  const [applyFiltersCounter, setApplyFiltersCounter] = useState<number>(0);
  const [loadingPerPage, setLoadingPerPage] = useState<boolean>(false);

  const [fetchBlocksLazy, { loading: loadingBlocks }] = useLazyQuery(
    BlockDocument,
    {
      client,
    },
  );
  const { loading: loadingStats, data: dashboardStats } = useQuery(
    DashboardStatsDocument,
    {
      client,
    },
  );
  const { loading: loadingMempoolTransactions, data: mempoolTransactions } =
    useQuery(TransactionsDocument, {
      client,
      variables: {
        first: TRANSACTIONS_IN_BLOCK,
        filter: {
          blockHeight: {
            equalTo: "0",
          },
        },
        withTotal: true,
      },
    });

  const transactionMetrics = dashboardStats?.dashboardStats?.nodes[0] || null;

  const checkForConflictingFilters = () => {
    const conflictingFilters = [
      !!filters.blockHeight,
      !!filters.blockHash,
      filters.date.from && filters.date.to,
    ];
    const totalConflictingFiltersApplied = conflictingFilters.filter((e) =>
      Boolean(e),
    );

    if (totalConflictingFiltersApplied.length >= 2) {
      const filterTypes = [];

      if (filters.blockHeight) {
        filterTypes.push("Block Height");
      }

      if (filters.blockHash) {
        filterTypes.push("Block Hash");
      }

      if (filters.date.from && filters.date.to) {
        filterTypes.push("Dates");
      }

      const toastMessage = `We applied your filters, but you may see no results if your filters conflict with one another. Please simplify your selection to a single filter only between ${filterTypes.join(
        ", ",
      )}`;
      toast({
        variant: "warning",
        title: "Conflicting filters found",
        duration: 15000,
        description: toastMessage,
      });
    }
  };

  const fetchBlocks = async (
    filter?: BlockFilter,
    transactionsNum: number = TRANSACTIONS_IN_BLOCK,
  ) => {
    const { data } = await fetchBlocksLazy({
      variables: {
        orderBy: TransactionsOrderBy.IndexInBlockAsc,
        transactionsFirst: transactionsNum,
        blocksFirst: itemsPerPage,
        blocksOrderBy: BlocksOrderBy.HeightDesc,
        transactionsOffset: 0,
        blocksOffset: offset,
        filter: !filter || Object.keys(filter).length > 0 ? filter : undefined,
      },
    });

    return {
      blocks:
        (data?.blocks?.nodes.filter((e) => e !== null) as Array<Block>) || [],
      total: data?.blocks?.totalCount || 0,
    };
  };

  useEffect(() => {
    const getBlocks = async () => {
      try {
        let filtersPayload: any = {};

        if (filters.blockHeight) {
          filtersPayload = {
            ...filtersPayload,
            height: {
              equalTo: filters.blockHeight,
            },
          };
        }
        if (filters.blockHash) {
          filtersPayload = {
            ...filtersPayload,
            blockHash: {
              equalTo: filters.blockHash,
            },
          };
        }
        if (filters.date.from && filters.date.to) {
          filtersPayload = {
            ...filtersPayload,
            timestamp: formatDateFiltersPayload(filters.date),
          };
        }

        const response: { blocks: Array<Block>; total: number } =
          await fetchBlocks(filtersPayload);

        if (response.total === 0) {
          checkForConflictingFilters();
        }

        setBlocks(response.blocks);
        setBlocksTotal(response.total);
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting blocks. ${JSON.stringify(
            e,
          )}`,
        });
      } finally {
        setLoadingPerPage(false);
        setInitLoading(false);
      }
    };

    getBlocks();
  }, [offset, itemsPerPage, applyFiltersCounter]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.blocksPerPage,
      itemsPerPage.toString(),
    );
  }, [itemsPerPage]);

  return (
    <main className="container-wide">
      <div className="text-left m-auto">
        <h1 className="text-2xl mb-4 text-black dark:text-white font-semibold">
          Blocks
        </h1>
      </div>

      <div className="mt-4 m-auto">
        <TransactionMetrics
          loading={loadingStats}
          dashboardStats={transactionMetrics}
          pendingTxns={mempoolTransactions?.transactions?.totalCount || 0}
        />

        <div className="my-12">
          <div className="flex justify-between items-center mb-6">
            <h3 className="flex items-center">
              <BoxIcon className="mr-2" />
              Mempool
            </h3>
          </div>

          {loadingMempoolTransactions ? (
            <div className="overflow-hidden rounded-xl">
              <Skeleton className="h-[48px] w-full rounded-none mb-1" />
              <Skeleton className="h-[100px] w-full rounded-none" />
            </div>
          ) : (
            <TableBlocks
              blocks={[
                {
                  height: MEMPOOL_LABEL,
                  transactions: mempoolTransactions?.transactions || {
                    nodes: [],
                    totalCount: 0,
                  },
                } as Block,
              ]}
              hideFooter={true}
              total={mempoolTransactions?.transactions?.totalCount || 0}
              loadingPage={loadingMempoolTransactions}
              offset={0}
              perPage={itemsPerPage}
            />
          )}
        </div>

        <div className="my-12">
          <div className="flex justify-between items-center mb-6">
            <div className="flex flex-col gap-1">
              <h3 className="flex items-center">
                <BoxIcon className="mr-2" />
                All Blocks
              </h3>
              <p className="text-xs w-[60%] leading-5 text-foreground">
                <span className="font-semibold text-muted-foreground">
                  Note:
                </span>{" "}
                The DeSo Explorer can take up to a few minutes to sync for
                blocks & transactions to appear. We apologize for the
                inconvenience, our team is currently improving the syncing
                process and will be resolved soon.
              </p>
            </div>

            <div className="flex items-center">
              {loadingPerPage && <Spinner className="mr-2" size={24} />}
              <span className="mr-3 inline-block">Show</span>
              <Select
                onValueChange={(e) => {
                  setOffset(0);
                  setLoadingPerPage(true);
                  setItemsPerPage(Number.parseInt(e));
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder={itemsPerPage} />
                </SelectTrigger>
                <SelectContent>
                  {PER_PAGE_OPTIONS.map((o) => (
                    <SelectItem key={o} value={o.toString()}>
                      {o}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex items-end justify-end p-4 py-2 border mb-6 rounded-xl">
            <BlockAdvancedFilters
              filters={filters}
              setFilters={(e) => {
                if (!e) {
                  return;
                }

                setOffset(0);
                setFilters(e);
              }}
              onApplyFilters={() => {
                setInitLoading(true);

                const possibleQueryFilters = pickBy(
                  {
                    height: filters.blockHeight,
                    hash: filters.blockHash,
                    from: filters.date.from
                      ? dayjs(filters.date.from).format("YYYY-MM-DD")
                      : undefined,
                    to: filters.date.to
                      ? dayjs(filters.date.to).format("YYYY-MM-DD")
                      : undefined,
                  },
                  identity,
                ) as { [param: string]: string };

                setSearchParams(possibleQueryFilters);

                setApplyFiltersCounter((prev) => prev + 1);
              }}
              onClearFilters={() => {
                setInitLoading(true);
                setFilters({ ...DEFAULT_FILTERS });
                setSearchParams({});
                setApplyFiltersCounter((prev) => prev + 1);
              }}
            />
          </div>

          {initLoading ? (
            <div className="overflow-hidden rounded-xl">
              <Skeleton className="h-[48px] w-full rounded-none mb-1" />
              <Skeleton className="h-[300px] w-full rounded-none" />
            </div>
          ) : (
            <TableBlocks
              blocks={blocks}
              total={blocksTotal}
              loadingPage={loadingBlocks}
              offset={offset}
              perPage={itemsPerPage}
              onPrevPage={() => {
                setOffset((prev) => prev - itemsPerPage);
              }}
              onNextPage={() => {
                setOffset((prev) => prev + itemsPerPage);
              }}
            />
          )}
        </div>
      </div>
    </main>
  );
};
